import React, { createContext } from 'react';

import axios from 'axios';
import { useAuth } from './auth';

const FetchContext = createContext();
const { Provider } = FetchContext;

function FetchProvider(props) {
  // const { user, renewToken, logout } = useAuth();
  const { user, logout } = useAuth();

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  authAxios.interceptors.request.use(
    (config) => {
      return {
        ...config,
        headers: { Authorization: `Bearer ${user.access}` },
      };
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  authAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
      // const originalRequest = error.config;
      // if (!error.response) {
      //   return Promise.reject(error);
      // }

      if (
        error.response.status === 401 &&
        error.response.statusText === 'Unauthorized'
      ) {
        // const renew = await renewToken();
        await logout();
        // console.log(renew);
        return Promise.reject(error);
        // originalRequest.headers.Authorization = `JWT ${renew.access}`;
        // return authAxios(originalRequest);
      }
      return Promise.reject(error);
    }
  );

  return (
    <Provider
      value={{
        client: authAxios,
      }}
      {...props}
    />
  );
}

const useFetch = () => React.useContext(FetchContext);

export { FetchProvider, useFetch };
