import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import classNames from 'classnames';

import './style.css';

function Spinner({ className }) {
  const customClassName = classNames('spinner', className);
  return <AiOutlineLoading3Quarters className={customClassName} />;
}

Spinner.propTypes = {
  className: PropTypes.string,
};

Spinner.defaultProps = {
  className: null,
};

export default Spinner;
