import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.css';

function Page({ header, footer, className, children, ...rest }) {
  const customClassName = classNames('page', className);
  return (
    <div className={customClassName} {...rest}>
      {header || null}
      <main>{children}</main>
      {footer ? <footer id="footer" /> : null}
    </div>
  );
}

Page.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.bool,
};

Page.defaultProps = {
  className: null,
  header: null,
  footer: true,
  children: null,
};

export default Page;
