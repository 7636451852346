import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.css';

function LayoutHorizontal({ children, className }) {
  const customClassName = classNames('layout -horizontal', className);

  return <div className={customClassName}>{children}</div>;
}

LayoutHorizontal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

LayoutHorizontal.defaultProps = {
  children: undefined,
  className: undefined,
};

export default LayoutHorizontal;
