import React, { Suspense } from 'react';

import { useUser } from 'context/user';

import 'react-toastify/dist/ReactToastify.css';

import FullPageLoading from 'components/full-page-loading';

import 'styles/toast.css';
import '@reach/dialog/styles.css';
import '@reach/tabs/styles.css';

import 'styles/reset.css';
import 'styles/tokens.css';
import 'styles/base.css';

import 'components/alert/styles.css';
import 'components/button/styles.css';
import 'components/button-icon/styles.css';
import 'components/input/styles.css';

const Public = React.lazy(() => import('./public'));
const Private = React.lazy(() => import('./private'));

Public.displayName = 'PublicRoutes';
Private.displayName = 'PrivateRoutes';

const { REACT_APP_VERSION, REACT_APP_API_URL, REACT_APP_ENV } = process.env;

console.info('Scalero env', REACT_APP_ENV);
console.info('Scalero app version: ', REACT_APP_VERSION);
console.info('Scalero API endpoint: ', REACT_APP_API_URL);

function App() {
  const user = useUser();
  return (
    <Suspense fallback={<FullPageLoading delay={300} footer={false} />}>
      {user ? <Private /> : <Public />}
    </Suspense>
  );
}

export default App;
