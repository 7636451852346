import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tooltip from 'components/tooltip';

import { FiXOctagon } from 'react-icons/fi';

import './styles.css';

function ErrorFallbackButtonIcon({ error, className, size }) {
  const customClassName = classNames(
    'error-fallback-button-icon',
    className,
    `-${size}`
  );

  return (
    <Tooltip
      className={customClassName}
      msg={<pre>{error.message}</pre>}
      options={{
        placement: 'auto',
      }}
    >
      <FiXOctagon className="button-icon" />
    </Tooltip>
  );
}

ErrorFallbackButtonIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }).isRequired,
};

ErrorFallbackButtonIcon.defaultProps = {
  className: null,
  size: 'm',
};

export default ErrorFallbackButtonIcon;
