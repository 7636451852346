import React, { useReducer, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { queryCache } from 'react-query';
import * as Sentry from '@sentry/react';

import { omitNull, isTokenExpired } from 'utils/tools';
import FullPageLoading from 'components/full-page-loading';

const AuthContext = React.createContext();
const storageKey = 'user';

const baseURL = process.env.REACT_APP_API_URL;
const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_USER':
      return { ...state, user: null, loading: true };
    case 'SET_USER':
      return { ...state, user: action.payload.user, loading: false };
    case 'REMOVE_USER':
      return { ...state, user: null, loading: false };
    default:
      throw new Error('Action not supported');
  }
};

const initialState = {
  user: null,
  loading: true,
};

function AuthProvider(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);

  const renewToken = useCallback(() => {
    let storedUser = localStorage.getItem(storageKey);

    try {
      JSON.parse(storedUser);
    } catch (e) {
      storedUser = null;
      localStorage.removeItem(storageKey);
      console.warn('Error parsing user token');
    }

    const user = storedUser ? JSON.parse(storedUser) : null;

    if (!user) {
      dispatch({ type: 'SET_USER', payload: { user: null } });
      return null;
    }

    // Access token and refresh token are expired
    if (isTokenExpired(user.access) && isTokenExpired(user.refresh)) {
      console.info('Access token and refresh token are expired');
      dispatch({ type: 'SET_USER', payload: { user: null } });
      localStorage.removeItem(storageKey);
      return null;
    }

    dispatch({ type: 'FETCH_USER' });

    // Access token is not expired
    if (!isTokenExpired(user.access)) {
      dispatch({ type: 'SET_USER', payload: { user } });
      console.info('Valid Access token');
      return null;
    }

    console.info('Access token expired refresh token still valid');
    // Access token expired refresh token still valid
    return (
      axios
        .post(`${baseURL}/login/token/refresh/`, { refresh: user.refresh })
        // eslint-disable-next-line no-use-before-define
        .then(handleUser)
        .catch((error) => {
          dispatch({ type: 'REMOVE_USER' });
          return Promise.reject(error);
        })
    );
  }, []);

  useEffect(() => {
    renewToken();
  }, [renewToken]);

  function handleUser(res) {
    if (!res.data) {
      console.error('Response is null');
      return;
    }

    const storedUser = localStorage.getItem(storageKey);
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;
    const user = { ...omitNull(parsedUser), ...omitNull(res.data) };

    localStorage.setItem(storageKey, JSON.stringify(user));
    dispatch({ type: 'SET_USER', payload: { user } });
  }

  const login = useCallback((values) => {
    return axios
      .post(`${baseURL}/login`, { ...values })
      .then(handleUser)
      .catch((error) => Promise.reject(error));
  }, []);

  function resetPasswordRequest(values) {
    return axios.post(`${baseURL}/forget-password/`, { ...values });
  }

  function resetPassword(values) {
    return axios.post(`${baseURL}/forget-password/done/`, { ...values });
  }

  function resetPasswordValidate(values) {
    return axios.get(
      `${baseURL}/forget-password/confirmation/${values.uid}/${values.token}/`,
      { ...values }
    );
  }

  function register(values) {
    return axios
      .post(`${baseURL}/registration/`, values)
      .catch((error) => Promise.reject(error));
  }

  const acceptInvite = useCallback((values) => {
    return axios
      .post(`${baseURL}/existing-team-invited-user/`, values)
      .then(handleUser)
      .catch((error) => Promise.reject(error));
  }, []);

  const logout = useCallback(() => {
    dispatch({ type: 'REMOVE_USER' });
    queryCache.clear();
    localStorage.removeItem(storageKey);
    history.push('/');
    if (process.env.NODE_ENV === 'production') {
      Sentry.configureScope((scope) => scope.setUser(null));
      window.heap.resetIdentity();
    }
  }, [history]);

  const value = React.useMemo(() => {
    return {
      user: state.user,
      login,
      logout,
      register,
      acceptInvite,
      renewToken,
      resetPassword,
      resetPasswordRequest,
      resetPasswordValidate,
    };
  }, [acceptInvite, login, logout, renewToken, state.user]);

  if (state.loading) {
    return <FullPageLoading footer={false} />;
  }

  return <AuthContext.Provider value={value} {...props} />;
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth, isTokenExpired };
