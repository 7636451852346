import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Page } from 'components/layouts';
import Spinner from 'components/spinner';

import './style.css';

function FullPageLoading({ delay, header, footer }) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return (
    <Page className="-full-page-loading" header={header} footer={footer}>
      {show ? (
        <div className="full-page-loading">
          <Spinner />
        </div>
      ) : null}
    </Page>
  );
}

FullPageLoading.propTypes = {
  delay: PropTypes.number,
  header: PropTypes.bool,
  footer: PropTypes.bool,
};

FullPageLoading.defaultProps = {
  delay: 0,
  header: null,
  footer: null,
};

export default FullPageLoading;
