import React from 'react';
import PropTypes from 'prop-types';
import { ReactQueryConfigProvider, setFocusHandler } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';

import { ErrorFallbackPage } from 'components/error-fallback';
import onWindowFocus from 'utils/on-window-focus';

import { AuthProvider } from './auth';
import { UserProvider } from './user';
import { FetchProvider } from './fetch';

// TODO: Improve Disable windows focus event on iframe
// https://react-query.tanstack.com/docs/guides/window-focus-refetching#ignoring-iframe-focus-events
const overrides = {
  queries: {
    refetchOnWindowFocus: true,
  },
};

function Context({ children }) {
  setFocusHandler(onWindowFocus);
  return (
    <React.StrictMode>
      <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
        <Router>
          <HelmetProvider>
            <AuthProvider>
              <FetchProvider>
                <ReactQueryConfigProvider config={overrides}>
                  <UserProvider>{children}</UserProvider>
                </ReactQueryConfigProvider>
              </FetchProvider>
            </AuthProvider>
          </HelmetProvider>
        </Router>
      </ErrorBoundary>
    </React.StrictMode>
  );
}

Context.propTypes = {
  children: PropTypes.node,
};

Context.defaultProps = {
  children: null,
};

export default Context;
