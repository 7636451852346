/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
// https://gist.github.com/tannerlinsley/1d3a2122332107fcd8c9cc379be10d88#file-onwindowfocus-js-L22

const state = {
  added: false,
  interval: false,
  inFrame: false,
  callbacks: [],
};

function start() {
  if (state.interval) {
    clearInterval(state.interval);
  }

  if (!state.added) {
    state.added = true;
    window.addEventListener('focus', (e) => {
      if (state.inFrame) {
        state.inFrame = false;
      } else {
        state.callbacks.forEach((cb) => cb(e));
      }
    });
  }

  state.interval = setInterval(() => {
    const iframes = [...document.getElementsByTagName('iframe')];

    iframes.forEach((el) => {
      if (el.___onWindowFocusHandled) {
        return;
      }
      el.___onWindowFocusHandled = true;
      el.addEventListener('touchend', () => {
        state.inFrame = true;
      });
      el.addEventListener('mouseup', () => {
        state.inFrame = true;
      });
      el.addEventListener('focus', () => {
        state.inFrame = true;
      });
    });
  }, 500);
}

function stop() {
  if (!state.callbacks.length) {
    clearInterval(state.interval);
  }
}

export default function onWindowFocus(cb) {
  state.callbacks.push(cb);
  start();
  return () => {
    state.callbacks = state.callbacks.filter((d) => d !== cb);
    stop();
  };
}
