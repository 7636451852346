import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.css';

function LayoutVertical({ children, className }) {
  const customClassName = classNames('layout -vertical', className);

  return <section className={customClassName}>{children}</section>;
}

LayoutVertical.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

LayoutVertical.defaultProps = {
  children: undefined,
  className: undefined,
};

export default LayoutVertical;
