import React from 'react';
import PropTypes from 'prop-types';

import { Page, LayoutHorizontal } from 'components/layouts';

import './styles.css';

// https://kentcdodds.com/blog/use-react-error-boundary-to-handle-errors-in-react
function ErrorFallbackPage({ error }) {
  return (
    <Page className="page-error" footer={false}>
      <LayoutHorizontal>
        <section className="error-illustration">
          <img src="/e-500.jpg" alt="error 500" />
        </section>
        <section className="error-message">
          <h1>
            Something
            <br /> isn’t quite right
          </h1>
          <h2>We have an internal server error</h2>
          <p>Try refreshing the page or contact support.</p>
          <pre>{error.message}</pre>
        </section>
      </LayoutHorizontal>
    </Page>
  );
}

ErrorFallbackPage.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }).isRequired,
};

export default ErrorFallbackPage;
