import React from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip as RKTooltip,
  TooltipReference,
  useTooltipState,
} from 'reakit/Tooltip';
import classNames from 'classnames';

import './style.css';

function Tooltip({ className, msg, children, options, ...rest }) {
  const tooltip = useTooltipState(options);
  const customClassName = classNames('tooltip', className);
  return (
    <div className={customClassName} {...rest}>
      <TooltipReference {...tooltip}>{children}</TooltipReference>
      <RKTooltip {...tooltip} className="tooltip-msg">
        {msg}
      </RKTooltip>
    </div>
  );
}

Tooltip.propTypes = {
  msg: PropTypes.node.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  options: PropTypes.shape({}),
};

Tooltip.defaultProps = {
  children: null,
  className: null,
  options: null,
};

export default Tooltip;
