import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { ReactQueryDevtools } from 'react-query-devtools';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactGA from 'react-ga';
import SimpleReactLightbox from 'simple-react-lightbox';

import App from './app';
import AppProviders from './context';
import * as serviceWorker from './serviceWorker';

const { REACT_APP_GOOGLE_ANALYTICS_CODE, REACT_APP_VERSION, REACT_APP_ENV } =
  process.env;

if (REACT_APP_ENV === 'prod') {
  window.heap.load('2262313048');
}

if (REACT_APP_ENV === 'prod' || REACT_APP_ENV === 'dev') {
  ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_CODE);
  ReactGA.pageview(window.location.pathname + window.location.search);
  Sentry.init({
    dsn: 'https://dca17c89ca6547949fc924fee0961278@o469260.ingest.sentry.io/5499435',
    integrations: [new Integrations.BrowserTracing()],
    release: `tiramisu-frontend@${REACT_APP_VERSION}`,
    tracesSampleRate: 1.0,
    environment: `${REACT_APP_ENV}`,
  });
}

ReactDOM.render(
  <AppProviders>
    <SimpleReactLightbox>
      <App />
    </SimpleReactLightbox>
    <ToastContainer />
    <ReactQueryDevtools />
  </AppProviders>,
  document.getElementById('scalero-app-root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
