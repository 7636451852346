export const sleep = (ms) =>
  new Promise((r) => {
    setTimeout(r, ms);
  });

export const omitNull = (obj) => {
  const o = { ...obj };
  Object.keys(o)
    .filter((k) => o[k] === null)
    .forEach((k) => delete o[k]);
  return o;
};

export const isTokenExpired = (token) => {
  if (!token) return true;
  const { exp } = JSON.parse(atob(token.split('.')[1]));
  return Date.now() >= exp * 1000;
};

export const sortAlphabetical = (a, b) =>
  a.label.toUpperCase().localeCompare(b.label.toUpperCase());

export const parseErrors = (data, values, setFieldError, setStatus) => {
  if (!data) {
    setStatus('Something went wrong please try again');
    return;
  }

  if (typeof data === 'string') {
    setStatus(data);
    return;
  }

  const errorsNotInValues = [];

  Object.entries(data).map((entry) => {
    const [key, value] = entry;
    const hasProperty = Object.prototype.hasOwnProperty.call(values, key);
    if (hasProperty) {
      setFieldError(key, value);
    } else {
      errorsNotInValues.push({ [key]: value });
    }
    return true;
  });

  if (errorsNotInValues.length > 0) {
    setStatus('Something went wrong please try again');
  }

  if (data.detail) {
    setStatus(data.detail);
  }

  if (data.non_field_errors) {
    setStatus(data.non_field_errors);
  }
};

export const findOptionByValue = (options, value) =>
  options.find((opt) => opt.value === value);

// Inclusive range function
// https://stackoverflow.com/questions/39924644/es6-generate-an-array-of-numbers
export const range = (start, end, step = 1) => {
  return Array.from(
    Array.from(Array(Math.ceil((end - start + 1) / step)).keys()),
    (x) => start + x * step
  );
};

export const genLoadingData = (count) => {
  return Array.from(Array(count).keys()).map(() => ({}));
};

export const clamp = (min, mid, max) => {
  return Math.min(Math.max(mid, min), max);
};

export function getSplitInitialWidth(comments = true) {
  let width = window.innerWidth - 320;

  if (comments) {
    width -= 540;
  }

  width = clamp(400, width, 1025);
  return width;
}

export const isNotEmpty = (str) => {
  return (str?.trim()?.length || 0) > 0;
};

export function inRange(n, a, b) {
  return n >= Math.min(a, b) && n < Math.max(a, b);
}

export function snakeToTitleCase(str) {
  return str
    .split('_')
    .map((item) => {
      return item.charAt(0).toUpperCase() + item.substring(1);
    })
    .join(' ');
}
