import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.css';

function LayoutGrid({ children, className }) {
  const customClassName = classNames('layout-grid', className);

  return <section className={customClassName}>{children}</section>;
}

LayoutGrid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

LayoutGrid.defaultProps = {
  children: undefined,
  className: undefined,
};

export default LayoutGrid;
